import { roundPrice } from '../../repositories/roundPrice'
import { orderBy as _orderBy } from 'lodash-es'

const statisticsModule = {
  namespaced: true,
  state: {
    model: 'TRAVEL_DATE_ATTRIBUTION',
    currentMonthRevenues: 0.0,
    averageBookingsAmount: 0,
    transactionsCount: 0,
    tripsBookedCount: 0,
    ticketsCount: 0,
    tripsTravelersCount: 0,
    rollingMonthlyEarnings: [],
    revenuesBreakdown: [],
    transactionsBreakdown: [],
    tripsPerCode: [],
    bookingsPerCode: [],
  },

  getters: {
    model: (state) => state.model,
    rollingEarningsTotal: (state) => {
      const total = state.rollingMonthlyEarnings.reduce(
        (acc, monthlyEarnings) => acc + monthlyEarnings.amount,
        0.0
      )
      return roundPrice(total, 2)
    },
  },

  mutations: {
    changeModel(state, value) {
      state.model = value
    },
    changeEarnings(
      state,
      { currentMonthRevenues, rollingMonthlyEarnings, revenuesBreakdown }
    ) {
      state.currentMonthRevenues = currentMonthRevenues
      state.rollingMonthlyEarnings = rollingMonthlyEarnings.sort(
        (a, b) => a.year - b.year || a.month - b.month
      )
      state.revenuesBreakdown = revenuesBreakdown || []
    },
    changeAveraveBookingsAmount(state, value) {
      state.averageBookingsAmount = value
    },
    changeTransactionsCount(state, value) {
      state.transactionsCount = value
    },
    changeTripsTraveledCount(state, value) {
      state.tripsTraveledCount = value
    },
    changeTripsBookedCount(state, value) {
      state.tripsBookedCount = value
    },
    changeTripsCount(state, { tripsBookedCount, tripsTraveledCount }) {
      state.tripsBookedCount = tripsBookedCount
      state.tripsTraveledCount = tripsTraveledCount
    },
    changeTicketsCount(state, value) {
      state.ticketsCount = value
    },

    changeBookingsPerCode(state, value) {
      state.bookingsPerCode = value
    },
    changeTripsPerCode(state, value) {
      state.tripsPerCode = value
    },
    changeAffiliationAmounts(state, value) {
      state.affiliationAmounts = value
    },
  },

  actions: {
    setModel({ commit }, value) {
      commit('changeModel', value)
    },
    setTripsTraveledCount({ commit }, value) {
      commit('changeTripsTraveledCount', value)
    },
    setTripsBookedCount({ commit }, value) {
      commit('changeTripsBookedCount', value)
    },
    setTransactionsCount({ commit }, value) {
      commit('changeTransactionsCount', value)
    },
    setStatistics({ commit }, payload) {
      const {
        model,
        rollingMonthlyEarnings,
        currentMonthRevenues,
        revenuesBreakdown,
        statistics,
        ticketsCount,
        tripsCount,
        bookingsPerCode,
        tripsPerCode,
      } = payload

      const { tripsTraveledCount } = statistics

      commit('changeModel', model)
      commit('changeEarnings', {
        currentMonthRevenues,
        rollingMonthlyEarnings,
        revenuesBreakdown,
      })
      commit(
        'changeAveraveBookingsAmount',
        roundPrice(statistics.averageBookingsAmount, 2)
      )
      commit('changeTransactionsCount', statistics.transactionsCount)
      commit('changeTripsCount', {
        tripsBookedCount: tripsCount,
        tripsTraveledCount,
      })
      commit('changeTicketsCount', ticketsCount)
      commit(
        'changeBookingsPerCode',
        bookingsPerCode.map((idStatistic) => ({
          name: idStatistic.affiliateId,
          value: idStatistic.transactionsPerId,
        }))
      )

      commit(
        'changeTripsPerCode',
        tripsPerCode.map((idStatistic) => ({
          name: idStatistic.affiliateId,
          value: idStatistic.transactionsPerId,
        }))
      )

      commit(
        'changeAffiliationAmounts',
        bookingsPerCode.map((idStatistic) => ({
          name: idStatistic.affiliateId,
          value: roundPrice(idStatistic.amountPerId, 2),
        }))
      )
    },
  },
}

export default statisticsModule
