<template>
  <div class="content">
    <div class="container-fluid">      
        <Notifications />
        
        <router-view v-slot="{ Component }">
          <transition name="fade">
            <component :is="Component" />
          </transition>
        </router-view>
    </div>
  </div>
</template>
<script>
  import DaterComponent from '@/components/shared/DaterComponent.vue'
import Notifications from '../../UIComponents/NotificationPlugin/Notifications.vue';
  export default {
    components: {
    DaterComponent,
    Notifications
},
  }
</script>
<style>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .1s
  }

  .fade-enter-from,
  .fade-leave-to
  {
    opacity: 0
  }
</style>
