// converts a dayjs date into something nicer
export const shortdate = (value) => {
    return value.format('MMMM YYYY')
}

// Add percent or euro symbol, according to the users model
export const affiliationModelViewSymbol = (model) => {
    let availableModels = (window.ferryhopper && window.ferryhopper.affiliation_models) || []
    let availableModel = availableModels.find(m => m.value === model)

    if (!availableModel) {
        return `U`;
    }

    return availableModel.percentage ? `%` : `€`;
}
export const affiliationModelTitle = (model) => {
    let availableModels = (window.ferryhopper && window.ferryhopper.affiliation_models) || []
    let availableModel = availableModels.find(m => m.value === model)

    if (!availableModel) {
        return `U`;
    }
    
    return availableModel.title;
}

export const affiliationRoundingAmounts = amount => {
    return Math.round(parseFloat(amount) * Math.pow(10, 2)) / Math.pow(10, 2)
}