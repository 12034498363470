<template>
  <div>
    <div class="text-right">
      <DaterComponent/>
    </div>
    <div class="card list-card" :class="{ busy: isWaiting }">
      <div class="content table-responsive table-full-width">
        <div class="form-group pull-left pl15">
          <input type="text" v-model="searchInput" placeholder="Search affiliates..." class="pull-left form-control"
                 style="border: 2px solid lightgray;"/>
        </div>
        <table class="table">
          <tbody>
          <tr>
            <th>Name</th>
            <th>Affiliation codes</th>
            <th>Model</th>
            <th>Commission</th>
            <th class="text-center">Invoices</th>
            <th class="text-center">
              <button @click="createMissingInvoices" v-if="hasMissingInvoices" class="btn btn-danger btn-fill btn-sm"
                      :disabled="isUiLocked">
                Calculate Invoices
              </button>
              <span v-else>Invoice actions</span>
            </th>
            <th>Actions</th>
          </tr>
          <tr v-for="(affiliate, index) in affiliateInvoices" :key="'affiliate-' + index">
            <!-- affiliate name -->

            <td>{{ affiliate.name }}</td>

            <!-- affiliate codes as labels -->
            <td>
              <AffiliationBubble v-for="code in affiliate.affiliationCodes" :affiliate="affiliate" :code="code"
                                 :key="code"/>
            </td>

            <!-- affiliate payment model -->
            <td>
              <span>{{ affiliationModelTitle(affiliate.scheme.affiliationModel) }}</span>
            </td>

            <!-- affiliate exact commissions -->
            <td>
                <span class="two-row-spans">
                  {{ affiliate.scheme.fixedCommission }}
                  <span>{{ affiliationModelViewSymbol(affiliate.scheme.affiliationModel) }}</span>
                </span>
              <span class="two-row-spans">
                  {{ affiliate.scheme.feeCommission }}
                  <span>{{ affiliationModelViewSymbol(affiliate.scheme.affiliationModel) }}</span>
                </span>
            </td>

            <!-- affiliate last month's invoice -->
            <td :id="'invoiceCell-' + index" class="text-center" style="position:relative;">
              <div class="cell-container" v-if="affiliate.invoice">
                <div class="left-cell-column">
                  <span class="two-row-spans">{{ affiliate.invoice.month }}/{{ affiliate.invoice.year }}</span>
                  <span class="two-row-spans" v-if="affiliate.invoice">{{ affiliate.invoice.amount }}€</span>
                </div>
                <div class="right-cell-column" style="text-align:left">
                  <i class="fa fa-info-circle clickable icon-centered-inside-cell" aria-hidden="true"
                     @click="onShowPerformance(index)"></i>
                </div>
              </div>
              <PerformanceInfoPopup v-if="index === idOfBanner && affiliate.invoice" @onHidePopUp="onHidePerformance"
                                    :revenuesBreakdown="affiliate.invoice.revenuesBreakdown"></PerformanceInfoPopup>
            </td>
            <!-- affiliate invoice actions -->
            <td class="text-center">
              <div v-if="affiliate.invoice">
                <button v-if="!affiliate.invoice.paid" class="btn btn-default btn-sm"
                        :disabled="isUiLocked"
                        @click="markInvoiceAsPaid(affiliate.invoice.id)">
                  Pay <i id="pay-icon" class="fa fa-money"></i>
                </button>
                <span v-else>
                    Paid
                    <i id="paid-icon" class="fa fa-check-circle"></i>
                  </span>
              </div>
              <div class="update-warning" v-else>
                <button class="btn btn-default btn-sm" :disabled="isUiLocked"
                        @click="calculateAffiliateInvoice(affiliate)">
                  Calculate invoice <i class="fa fa-money"></i>
                </button>
              </div>
            </td>

            <!-- affiliate edit button -->
            <td>
              <router-link :to="{ name: 'Affiliate', params: { code: affiliate.code } }">
                <button class="btn btn-default btn-sm">
                  Edit <i class="fa fa-edit"></i>
                </button>
              </router-link>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import AffiliationBubble from '@/components/shared/AffiliationBubble'
import {affiliationModelViewSymbol, affiliationModelTitle} from '@/filters'
import {includes as _includes} from 'lodash-es';
import DaterComponent from '@/components/shared/DaterComponent.vue'
import PerformanceInfoPopup from '@/components/UIComponents/PerformanceInfoPopUp'
import {mapGetters, mapState} from 'vuex'
import ApiGateway from '../services/ApiGateway'

export default {
  components: {
    DaterComponent,
    PerformanceInfoPopup,
    AffiliationBubble
  },
  data: () => ({
    affiliatesList: [],
    searchInput: '',
    idOfBanner: '',
    isWaiting: false
  }),
  watch: {
    // Executes instantly once component comes into view, and each time the date changes
    getActiveDates(previous, current) {
      this.getAffiliatesList();
    }
  },
  computed: {
    ...mapGetters({
      getActiveDates: 'datesModule/getActiveDates'
    }),
    ...mapState({
      isUiLocked: state => state.lockUi.blockUi
    }),
    affiliateInvoices() {
      return this.affiliatesList.filter(affiliate => {
        return this.searchForInputMatches(affiliate)
      })
    },
    hasMissingInvoices() {
      return this.affiliatesList.some(affiliate => {
        return !affiliate.invoice
      })
    }
  },
  methods: {
    affiliationModelViewSymbol,
    affiliationModelTitle,
    getAffiliatesList() {
      this.isWaiting = true;
      ApiGateway.affiliatesList().then(({data}) => {
        this.affiliatesList = data;
      }).finally(() => this.isWaiting = false);
    },
    /**
     * *this method is called when the pay button for an affiliate is pressed,
     * *the invoice is set to paid in database and the paid flag is shown to the user
     */
    markInvoiceAsPaid(invoiceId) {
      this.$store.dispatch('lockUi/lockUiAction')
      ApiGateway.setPaid(invoiceId).then(() => {
        this.affiliatesList = this.affiliatesList.map(affiliate => {
          if ((affiliate.invoice) && (affiliate.invoice.id === invoiceId)) {
            return {
              ...affiliate,
              invoice: {
                ...affiliate.invoice,
                paid: true
              }
            }
          }

          return affiliate;
        })
        this.$store.dispatch('lockUi/unlockUiAction')
      })
    },

    calculateAffiliateInvoice(affiliate) {
      this.$store.dispatch('lockUi/lockUiAction')
      ApiGateway.createInvoice(affiliate.id).then(({data}) => {
        this.affiliatesList = data;
        this.$forceUpdate()
      })
        .catch(error => this.$store.dispatch('notificationsModule/errorNotify', error.toString()))
        .finally(() => this.$store.dispatch('lockUi/unlockUiAction'));
    },
    /**
     * * update the invoices that aren't calculated yet
     * * the invoices are calculated for the previous month of the date we have in the
     * * dater component
     */
    createMissingInvoices() {
      this.$store.dispatch('lockUi/lockUiAction')
      ApiGateway.createInvoices().then(({data}) => {
        this.affiliatesList = data;
        this.$forceUpdate()
        this.$store.dispatch('lockUi/unlockUiAction')
      })
    },
    /**
     * * we turn mastercode array into string and concat with name
     * * so that the includes method can check if the input exists as a part of the string
     * ! toUpperCase function is used to make search not case sensitive
     * @param affiliate
     */
    searchForInputMatches(affiliate) {
      let affiliationCodesString = affiliate.affiliationCodes.toString()
      let searchString = affiliationCodesString + affiliate.name

      return _includes(
        searchString.replace(/,/g, '').toUpperCase(),
        this.searchInput.toUpperCase()
      )
    },
    onShowPerformance(id) {
      this.idOfBanner = id
    },
    onHidePerformance() {
      this.idOfBanner = -1
    },
  },
}
</script>
